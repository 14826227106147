import React from "react"

import Layout from "../components/Layout"
import TimetableNotice from "../components/Content/TimetableNotice"
import Heading from "../components/Layout/heading"
import Spacer from "../components/Layout/spacer"
import DayTours from "../components/DayTours"
import FeaturedTours from "../components/FeaturedTours"
import ExtendedTours from "../components/ExtendedTours"
import ContactForm from "../components/Forms/contact"

import SEO from "../components/SEO"

import fiftyyears from "../images/50-years.jpg"

const Index = (props) => (
  <Layout location={props.location}>
    <SEO
      title="Stuarts Coaches - Welcome Onboard!"
      description="Stuarts Coaches is a locally owned and operated bus and coach
          business established in 1969"
    />
    <TimetableNotice />
    <Heading title="Welcome Onboard!" />
    <div className="row main-page justify-content-center">
      <div className="col-12 col-md-8 index-page-text">
        <p>
          Stuarts Coaches is a locally owned and operated bus and coach
          business.
        </p>
        <p>
          It was established in 1969 by Ron and Ross Stuart. Starting with a
          school run and passenger service to Nowra, a Coach was bought in 1971
          and tours to Central Australia commenced. During this period Ross' son
          Kevin became involved in the business and in 1982 joined the business
          full time.
        </p>
        <p>
          In 2007 Kevin bought Ross' share of the business and operates it in
          conjunction with his wife Leanne.
        </p>
        <p>
          Currently Stuarts Coaches operates tours to destinations all around
          Australia and regular trips to various locations overseas. A travel
          club operates with currently 2500 members who enjoy travel as well as
          social functions.
        </p>
      </div>
      <div className="col-8 col-md-4">
        <img src={fiftyyears} alt="" className="index-image" />
      </div>
    </div>
    <Spacer />
    <FeaturedTours perPage={2} />
    <Spacer />
    <ExtendedTours perPage={5} frontpage />
    <Spacer />
    <DayTours perPage={5} frontpage />
    <Spacer />
    <Heading title="Get in touch" />
    <ContactForm />
  </Layout>
)

export default Index
