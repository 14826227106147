import React from "react"
import Moment from "react-moment"

import Button from "react-bootstrap/Button"
import Icon from "../Icon"

import "./ListFeaturedTours.scss"

const fileBucket = process.env.FILE_BUCKET

const ListFeaturedTours = ({ featuredTours }) => {
  if (featuredTours.length > 0) {
    const listFeaturedTours = featuredTours.map((featuredTour) => {
      const imageSplit = featuredTour.image.split(".")
      const filename = imageSplit[0].replace(/ /g, "%20")
      return (
        <div className="row featuredtour" key={featuredTour.id}>
          <div className="col-12 col-md-4">
            <img
              src={`${fileBucket}/extended_tours/images/${filename}_750.${imageSplit[1]}`}
              className="featuredtour-image"
              alt=""
              srcSet={`
            ${fileBucket}/extended_tours/images/${filename}_1000.${imageSplit[1]} 1000w,
            ${fileBucket}/extended_tours/images/${filename}_750.${imageSplit[1]} 750w,
            ${fileBucket}/extended_tours/images/${filename}_500.${imageSplit[1]} 500w,
            ${fileBucket}/extended_tours/images/${filename}_300.${imageSplit[1]} 300w
          `}
              sizes="
          (max-width: 768px) 100vw,
          33vw
          "
              role="presentation"
              loading="lazy"
            />
          </div>
          <div className="col-12 col-md-8">
            <h4 className="featuredtour-title">{featuredTour.tour}</h4>
            <span className="featuredtour-date">
              <Moment
                format="Do MMMM YYYY"
                date={featuredTour.departureDate}
                unix
                local
              />
              {" - "}
              <Moment
                format="Do MMMM YYYY"
                date={featuredTour.returnDate}
                unix
                local
              />
            </span>
            <p className="featuredtour-description">
              {featuredTour.description}
            </p>
            {featuredTour.brochure && (
              <a
                href={`${fileBucket}/extended_tours/pdfs/${featuredTour.brochure}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="primary" bsPrefix="stu">
                  <Icon prefix="far" name="file-pdf" /> View Brochure
                </Button>
              </a>
            )}
            {featuredTour.itinerary && (
              <a
                href={`${fileBucket}/extended_tours/pdfs/${featuredTour.itinerary}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="primary" bsPrefix="stu">
                  <Icon prefix="far" name="file-pdf" /> View Itinerary
                </Button>
              </a>
            )}
          </div>
        </div>
      )
    })
    return listFeaturedTours
  } else {
    return (
      <div className="row featuredtour">
        <div className="col-12">
          <span>No Featured Tours to display</span>
        </div>
      </div>
    )
  }
}

export default ListFeaturedTours
