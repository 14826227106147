import React, { useState, useEffect } from "react"
import axios from "axios"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

import Loader from "../Loader"
import Message from "../Message"
import Icon from "../Icon"
import Pagination from "../Pagination"
import ListDayTours from "./ListDayTours"
import TripDisclaimer from "../Content/TripDisclaimer"

import "./index.scss"

const API = process.env.API

const DayTours = (props) => {
  const [dayTours, setDayTours] = useState({})
  const [message, setMessage] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState()

  useEffect(() => {
    setIsLoading(true)
    axios
      .get(`${API}/daytours?page=${page}&per-page=${props.perPage}`)
      .then((response) => {
        setDayTours(response.data.dayTours)
        setTotal(response.data.total)
        setIsLoading(false)
      })
      .catch((error) => {
        setMessage({
          type: "danger",
          text: error.response.data.message,
        })
        setIsLoading(false)
      })
  }, [props.perPage, page])

  return (
    <>
      {message ? (
        <Message type={message.type} message={message.text} />
      ) : isLoading ? (
        <>
          <div className="daytours-container">
            <div className="row">
              <div className="col text-center">
                <h2>Day Tours</h2>
              </div>
            </div>
            <Loader />
          </div>
        </>
      ) : (
        <>
          <div className="daytours-container">
            <div className="row">
              <div className="col text-center">
                <h2>Day Tours</h2>
              </div>
            </div>
            <ListDayTours dayTours={dayTours} />
            <TripDisclaimer />
            {props.frontpage ? (
              total > dayTours.length && (
                <div className="row justify-content-center">
                  <div className="col-10 col-md-8 col-lg-6 text-center view-all-button-container">
                    <Link to="/services/day-tours">
                      <Button variant="outline-primary" size="lg" block>
                        View all Day Tours{" "}
                        <Icon prefix="fas" name="angle-right" />
                      </Button>
                    </Link>
                  </div>
                </div>
              )
            ) : (
              <Pagination
                page={page}
                perPage={props.perPage}
                total={total}
                setPage={setPage}
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

export default DayTours
