import React, { useState, useEffect } from "react"
import axios from "axios"

import Loader from "../Loader"
import Message from "../Message"
import ListFeaturedTours from "./ListFeaturedTours"
import TripDisclaimer from "../Content/TripDisclaimer"

import "./index.scss"

const API = process.env.API

const FeaturedTours = (props) => {
  const [featuredTours, setFeaturedTours] = useState({})
  const [message, setMessage] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    axios
      .get(`${API}/featured/extendedtours?page=1&per-page=${props.perPage}`)
      .then((response) => {
        setFeaturedTours(response.data.featuredTours)
        setIsLoading(false)
      })
      .catch((error) => {
        setMessage({
          type: "danger",
          text: error.response.data.message,
        })
        setIsLoading(false)
      })
  }, [props.perPage, props.featured])

  return (
    <>
      {message ? (
        <Message type={message.type} message={message.text} />
      ) : isLoading ? (
        <>
          <div className="featuredtours-container">
            <div className="row">
              <div className="col text-center">
                <h2>Featured Tours</h2>
              </div>
            </div>
            <Loader />
          </div>
        </>
      ) : (
        <>
          <div className="featuredtours-container">
            <div className="row">
              <div className="col text-center">
                <h2>Featured Tours</h2>
              </div>
            </div>
            <ListFeaturedTours featuredTours={featuredTours} />
            <TripDisclaimer />
          </div>
        </>
      )}
    </>
  )
}

export default FeaturedTours
