import React, { useState } from "react"
import Axios from "axios"
import Recaptcha from "react-recaptcha"
import Button from "react-bootstrap/Button"

import useForm from "./Hooks/useForm"
import validate from "./Validation/Contact"

import Message from "../Message"
import Icon from "../Icon"
import Feedback from "./Feedback"

import "./styles.scss"

const API = process.env.API
const recaptchaKey = process.env.GOOGLE_RECAPTCHA_KEY
let recaptchaInstance

const ContactForm = () => {
  const [message, setMessage] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    handleChange,
    handleSubmit,
    handleReCaptcha,
    resetReCaptcha,
    values,
    errors,
  } = useForm(submit, validate)

  function submit() {
    setMessage({})
    setIsSubmitting(true)
    Axios.post(`${API}/forms/contact`, values)
      .then(() => {
        setIsSubmitting(false)
        setMessage({
          type: "success",
          text: "Your enquiry has been sent",
        })
        resetReCaptcha(recaptchaInstance)
      })
      .catch(() => {
        setIsSubmitting(false)
        setMessage({
          type: "danger",
          text: "Your enquiry failed to send",
        })
        resetReCaptcha(recaptchaInstance)
      })
  }

  return (
    <>
      <div className="row">
        <div className="col-md-8 text-center contact-form">
          <form onSubmit={handleSubmit} noValidate>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <input
                        onChange={handleChange}
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        aria-label="Full Name"
                      />
                      <Feedback message={errors.name} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <input
                        onChange={handleChange}
                        name="phone"
                        className="form-control"
                        placeholder="Phone"
                        aria-label="Phone Number"
                      />
                      <Feedback message={errors.phone} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <input
                        onChange={handleChange}
                        name="email"
                        className="form-control"
                        placeholder="E-Mail"
                        aria-label="Email Address"
                      />
                      <Feedback message={errors.email} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <textarea
                        onChange={handleChange}
                        name="message"
                        className="form-control"
                        rows="6"
                        placeholder="Message"
                        aria-label="Your Message"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col text-left">
                <div className="form-group">
                  <Recaptcha
                    ref={(e) => (recaptchaInstance = e)}
                    sitekey={recaptchaKey}
                    render="explicit"
                    onloadCallback={() => {}}
                    verifyCallback={(response) => {
                      handleReCaptcha(response)
                    }}
                  />
                  <Feedback message={errors.reCaptcha} />
                </div>
                <Message type={message.type} message={message.text} />
                <div className="form-group">
                  {isSubmitting ? (
                    <Button type="submit" disabled bsPrefix="stu">
                      Sending <Icon prefix="fas" name="spinner" spin />
                    </Button>
                  ) : (
                    <Button type="submit" onClick={handleSubmit} bsPrefix="stu">
                      Send <Icon prefix="fas" name="chevron-right" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-4 contact-details">
          <div className="row contact-phone my-auto no-gutters">
            <div className="col-2 text-center">
              <Icon prefix="fas" name="phone-alt" />
            </div>
            <div className="col-10">
              <a href="tel:0244210332">(02) 4421 0332</a>
            </div>
          </div>
          <div className="row contact-location my-auto no-gutters">
            <div className="col-2 text-center">
              <Icon prefix="fas" name="location-arrow" />
            </div>
            <div className="col-10 ">
              <span>
                339 Greenwell Point Road <br /> Worrigee NSW 2541
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactForm
