import React from "react"
import Moment from "react-moment"

import "./ListDayTours.scss"

const fileBucket = process.env.FILE_BUCKET

const ListDayTours = ({ dayTours }) => {
  if (dayTours.length > 0) {
    const listDayTours = dayTours.map((dayTour) => {
      const imageSplit = dayTour.image.split(".")
      const filename = imageSplit[0].replace(/ /g, "%20")
      return (
        <div className="row daytour" key={dayTour.id}>
          <div className="col-12 col-md-4">
            <img
              src={`${fileBucket}/day_tours/images/${filename}_750.${imageSplit[1]}`}
              className="daytour-image"
              alt=""
              srcSet={`
            ${fileBucket}/day_tours/images/${filename}_1000.${imageSplit[1]} 1000w,
            ${fileBucket}/day_tours/images/${filename}_750.${imageSplit[1]} 750w,
            ${fileBucket}/day_tours/images/${filename}_500.${imageSplit[1]} 500w,
            ${fileBucket}/day_tours/images/${filename}_300.${imageSplit[1]} 300w
          `}
              sizes="
          (max-width: 768px) 100vw,
          33vw
          "
              role="presentation"
              loading="lazy"
            />
          </div>
          <div className="col-12 col-md-8">
            <h4 className="daytour-title">{dayTour.title}</h4>
            <span className="daytour-date">
              <Moment format="Do MMMM YYYY" date={dayTour.date} unix local />
            </span>
            <p className="daytour-description">{dayTour.description}</p>
            {dayTour.cost && (
              <>
                <span>
                  <b>Cost: </b>
                  {dayTour.cost}
                </span>
                <br />
              </>
            )}
            {dayTour.includes && (
              <>
                <span>
                  <b>Includes: </b>
                  {dayTour.includes}
                </span>
                <br />
              </>
            )}
            {dayTour.departs && (
              <>
                <span>
                  <b>Departs: </b>
                  {dayTour.departs}
                </span>
                <br />
              </>
            )}
          </div>
        </div>
      )
    })
    return listDayTours
  } else {
    return (
      <div className="row daytour">
        <div className="col-12">
          <span>No Day Tours to display</span>
        </div>
      </div>
    )
  }
}

export default ListDayTours
