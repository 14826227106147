import React, { useState, useEffect } from "react"
import axios from "axios"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

import Loader from "../Loader"
import Message from "../Message"
import Icon from "../Icon"
import Pagination from "../Pagination"
import ListExtendedTours from "./ListExtendedTours"
import TripDisclaimer from "../Content/TripDisclaimer"

import "./index.scss"

const API = process.env.API

const ExtendedTours = (props) => {
  const [extendedTours, setExtendedTours] = useState({})
  const [message, setMessage] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState()

  useEffect(() => {
    setIsLoading(true)
    let request = ""
    if (props.frontpage) {
      request = `${API}/extendedtours?page=${page}&per-page=${props.perPage}`
    } else {
      request = `${API}/all/extendedtours?page=${page}&per-page=${props.perPage}`
    }
    axios
      .get(request)
      .then((response) => {
        setExtendedTours(response.data.extendedTours)
        setTotal(response.data.total)
        setIsLoading(false)
      })
      .catch((error) => {
        setMessage({
          type: "danger",
          text: error.response.data.message,
        })
        setIsLoading(false)
      })
  }, [props.perPage, page, props.frontpage])

  return (
    <>
      {message ? (
        <Message type={message.type} message={message.text} />
      ) : isLoading ? (
        <>
          <div className="extendedtours-container">
            <div className="row">
              <div className="col text-center">
                <h2>Extended Tours</h2>
              </div>
            </div>
            <Loader />
          </div>
        </>
      ) : (
        <>
          <div className="extendedtours-container">
            <div className="row">
              <div className="col text-center">
                <h2>Extended Tours</h2>
              </div>
            </div>
            <ListExtendedTours extendedTours={extendedTours} />
            <TripDisclaimer />
            {props.frontpage ? (
              total > extendedTours.length && (
                <div className="row justify-content-center">
                  <div className="col-10 col-md-8 col-lg-6 text-center view-all-button-container">
                    <Link to="/services/extended-tours">
                      <Button variant="outline-primary" size="lg" block>
                        View all Extended Tours{" "}
                        <Icon prefix="fas" name="angle-right" />
                      </Button>
                    </Link>
                  </div>
                </div>
              )
            ) : (
              <Pagination
                page={page}
                perPage={props.perPage}
                total={total}
                setPage={setPage}
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

export default ExtendedTours
