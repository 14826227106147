import React from "react"

import "./spacer.scss"

import iconGrey from "../../images/icon-grey.jpg"

const Spacer = () => (
  <div className="row">
    <div className="col-12">
      <div className="spacer">
        <div className="spacer-icon-container">
          <img src={iconGrey} alt="" className="spacer-icon" />
        </div>
      </div>
    </div>
  </div>
)

export default Spacer
