import React from "react"
import Moment from "react-moment"

import Button from "react-bootstrap/Button"
import Icon from "../Icon"

import "./ListExtendedTours.scss"

const fileBucket = process.env.FILE_BUCKET

const ListExtendedTours = ({ extendedTours }) => {
  if (extendedTours.length > 0) {
    const listExtendedTours = extendedTours.map((extendedTour) => {
      const imageSplit = extendedTour.image.split(".")
      const filename = imageSplit[0].replace(/ /g, "%20")
      return (
        <div className="row extendedtour" key={extendedTour.id}>
          <div className="col-12 col-md-4">
            <img
              src={`${fileBucket}/extended_tours/images/${filename}_750.${imageSplit[1]}`}
              className="extendedtour-image"
              alt=""
              srcSet={`
            ${fileBucket}/extended_tours/images/${filename}_1000.${imageSplit[1]} 1000w,
            ${fileBucket}/extended_tours/images/${filename}_750.${imageSplit[1]} 750w,
            ${fileBucket}/extended_tours/images/${filename}_500.${imageSplit[1]} 500w,
            ${fileBucket}/extended_tours/images/${filename}_300.${imageSplit[1]} 300w
          `}
              sizes="
          (max-width: 768px) 100vw,
          33vw
          "
              role="presentation"
              loading="lazy"
            />
          </div>
          <div className="col-12 col-md-8">
            <h4 className="extendedtour-title">{extendedTour.tour}</h4>
            <span className="extendedtour-date">
              <Moment
                format="Do MMMM YYYY"
                date={extendedTour.departureDate}
                unix
                local
              />
              {" - "}
              <Moment
                format="Do MMMM YYYY"
                date={extendedTour.returnDate}
                unix
                local
              />
            </span>
            <p className="extendedtour-description">
              {extendedTour.description}
            </p>
            {extendedTour.brochure && (
              <a
                href={`${fileBucket}/extended_tours/pdfs/${extendedTour.brochure}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="primary" bsPrefix="stu">
                  <Icon prefix="far" name="file-pdf" /> View Brochure
                </Button>
              </a>
            )}
            {extendedTour.itinerary && (
              <a
                href={`${fileBucket}/extended_tours/pdfs/${extendedTour.itinerary}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="primary" bsPrefix="stu">
                  <Icon prefix="far" name="file-pdf" /> View Itinerary
                </Button>
              </a>
            )}
          </div>
        </div>
      )
    })
    return listExtendedTours
  } else {
    return (
      <div className="row extendedtour">
        <div className="col-12">
          <span>No Extended Tours to display</span>
        </div>
      </div>
    )
  }
}

export default ListExtendedTours
